import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useStaticQuery, graphql } from 'gatsby';
import slugify from 'slugify';
import Layout from '../containers/layout';
import PageTransition from '../components/common/page-transition';
import PaddedPage from '../components/common/padded-page';
import ProjectCard from '../components/projects/project-card';

const query = graphql`
  query Projects {
    allMarkdownRemark(
      sort: { fields: [frontmatter___sort_date], order: DESC }
      filter: {fileAbsolutePath: {regex: "../content/projects/.*.md$/"}}
    ) {
      edges {
        node {
          frontmatter {
            title
            subtitle
            main_image {
              childImageSharp {
                fluid(maxWidth: 750) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

const PageContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const CardsContainer = styled(motion.div)`
  width: 100%;
  box-sizing: border-box;
  display: grid;
  grid-auto-rows: auto;
  max-width: 1000px;
  margin-bottom: 20px;

  grid-column-gap: 5vw;
  grid-row-gap: 7vw;
  grid-template-columns: repeat(1, 1fr);
  @media only screen and (min-width: 750px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media only screen and (min-width: 1400px) {
    grid-column-gap: 80px;
    grid-row-gap: 100px;
  }
`;

// Create the slug based on the title.
const createSlug = (title) => slugify(title, {
  lower: true,
});

const Projects = () => {
  const projects = useStaticQuery(query).allMarkdownRemark.edges;
  return (
    <Layout>
      <PageTransition>
        <PaddedPage>
          <PageContent>
            <CardsContainer
              variants={{
                visible: {
                  transition: {
                    staggerChildren: 0.2,
                  },
                },
              }}
              initial="hidden"
              animate="visible"
            >
              {projects.map(({ node }) => {
                const { main_image: image, title, subtitle } = node.frontmatter;
                return (
                  <ProjectCard
                    key={title}
                    slug={createSlug(title)}
                    image={image}
                    title={title}
                    subtitle={subtitle}
                  />
                );
              })}
            </CardsContainer>
          </PageContent>
        </PaddedPage>
      </PageTransition>
    </Layout>
  );
};

export default Projects;
