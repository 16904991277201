import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import Img from 'gatsby-image';
import TransitioningLink from '../common/transitioning-link';

const Card = styled(motion.div)`
  position: relative;
  color: ${({ theme }) => theme.colors.primary};
  text-transform: uppercase;
  
  margin-top: 0;
  @media only screen and (min-width: 750px) {
    /* Shift the first card in the second column down. */
    &:nth-child(2) {
      margin-top: 70px;
    }
    /* Shift up all cards in first column. */
    &:nth-child(odd) {
      margin-top: -70px;
    }
    /* Don't shift the first card in the first column. */
    &:nth-child(1) {
      margin-top: 0;
    }
  }
`;

const ImageLink = styled(TransitioningLink)`
  &:hover ~ .card-text {
    cursor: pointer;
    &:before {
      visibility: visible;
      transform: scaleX(1);
    }
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;

  &:before {
    content: "";
    display: block;
    padding-top: 100%;
  }

  margin-bottom: 10px;

  & .gatsby-image-wrapper {
    position: absolute !important;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
  }
`;

const Image = styled(({ image, ...props }) => <Img {...props} />)`
  transition: transform 0.6s ease-out;
  transform: scale(1);
  &:hover {
    cursor: pointer;
    transform: scale(1.2);
  }
`;

const TextLink = styled(TransitioningLink)`
  width: auto;
  display: inline-block;
  position: relative;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.primary};

  &:hover {
    cursor: pointer;
    &:before {
      visibility: visible;
      transform: scaleX(1);
    }
  }

  &:before {
    content: "";
    transition: all 0.2s;
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -4px;
    left: 0;
    background-color: ${({ theme }) => theme.colors.primary};
    visibility: hidden;
    transform: scaleX(0);
  }
`;

// Title font size is 1.5x subtitle font size
const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
`;

const Subtitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  opacity: 0.7;
`;

const ProjectCard = ({
  slug, image, title, subtitle,
}) => (
  <Card
    variants={{
      hidden: {
        opacity: 0,
        y: 150,
      },
      visible: {
        opacity: 1,
        y: 0,
      },
    }}
    transition={{
      ease: 'easeOut',
      duration: 1.2,
    }}
  >
    <ImageLink to={`/projects/${slug}`}>
      <ImageContainer>
        <Image fluid={{ ...image.childImageSharp.fluid, aspectRatio: 1 }} />
      </ImageContainer>
    </ImageLink>
    <TextLink className="card-text" to={`/projects/${slug}`}>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
    </TextLink>
  </Card>
);

ProjectCard.propTypes = {
  slug: PropTypes.string.isRequired,
  image: PropTypes.objectOf(PropTypes.object).isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default ProjectCard;
